<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Companies</div>
        </div>
      </v-col>
      <v-col md="6" cols="12" class="d-flex justify-end">
        <div class="conOfHeader text-right mr-2">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="dialogImport = true">
              <v-icon>mdi-upload</v-icon>
              Import
            </v-btn>
          </div>
        </div>

        <div class="conOfHeader text-right">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="dialogProject = true">
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <form
          v-on:submit.prevent="searchCampanies()"
          class="conFormSubmit conSearchForm mt-5"
        >
          <v-row>
            <v-col cols="12">
              <label class="eachLabel">Search</label>

              <v-text-field
                outlined
                v-model="searchQuery"
                @input="searchCampanies"
                type="text"
                class="form-control"
                id="inputSearch"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                append-icon="mdi-magnify"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.suggested_name`]="{ item }">
                <v-row v-if="item.suggested_name">
                  <div>{{ item.suggested_name.new_name }}</div>
                  <div class="d-flex justify-between mt-2 mb-2">
                    <v-btn
                      class="mr-2 green darken-2 white--text"
                      @click="approveSuggestedName(1, item.id)"
                      :loading="isLoadingSave"
                      :disabled="isLoadingSave"
                    >
                      approve
                    </v-btn>
                    <v-btn
                      class="red darken-2 white--text"
                      @click="approveSuggestedName(0, item.id)"
                      :loading="isLoadingSave"
                      :disabled="isLoadingSave"
                    >
                      Decline
                    </v-btn>
                  </div>
                </v-row>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-row v-if="item.status == 0">
                  <div class="mr-2 d-flex align-center">Not Approved</div>
                </v-row>
                <v-row v-else>
                  <div class="mr-2 d-flex align-center">Approved</div>
                </v-row>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-title class="statusTxt">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <template>
                      <v-list-item v-if="item.status == 0">
                        <v-list-item-title
                          @click="approveItem(item, 1)"
                          class="statusTxt"
                        >
                          Approve
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else>
                        <v-list-item-title
                          @click="approveItem(item, 0)"
                          class="statusTxt"
                          >Edit Approval
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog scrollable v-model="dialogProject" max-width="500">
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Company Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Company Name"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.name"
                  required
                  :error-messages="nameErrors"
                  @input="$v.formItem.name.$touch()"
                  @blur="$v.formItem.name.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogApprove" max-width="500">
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Approve New Company</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Company Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Company Name"
                  outlined
                  solo
                  hide-details="auto"
                  disabled
                  v-model="formItem.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeApproveModal">
            Cancel
          </v-btn>
          <v-btn
            v-if="status == 1"
            class="btnPrimaryOrg"
            @click="approveNewCompany(status, formItem.id, formItem.name)"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            appprove
          </v-btn>
          <v-btn
            v-else
            class="btnPrimaryOrg"
            @click="approveNewCompany(status, formItem.id, formItem.name)"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Disappprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogImport" max-width="500">
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Import Data</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <input type="file" id="file" ref="file" @change="uploadFile" />
              </v-col>
              <v-col cols="12"> Please upload xlsx with title name </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeImportModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="submitFile"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EmptyState from "@/modules/shared/components/emptystate";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        name: { required },
      },
    };
    return validations;
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    searchQuery: "",
    dialogProject: false,
    dialogApprove: false,
    dialogImport: false,
    isLoadingSave: false,
    isLoading: false,
    file: null,
    searchTxt: "",
    updatedFilterTxt: "",
    editedIndex: -1,
    errorMessage: null,
    pagination: {
      current_page: 1,
    },
    error: {},
    status: null,
    formItem: {},
    defaultItem: {},
    /*    headers: [
      {
        text: "NAME",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "CREATE DATE", value: "created_at" },
            { text: "Suggested Name", value: "suggested_name" },
                        { text: "Approve", value: "status" },


      { text: "", value: "actions", sortable: false, align: "right" },
    ], */
    listData: [],
  }),
  components: { EmptyState },
  computed: {
    ...mapGetters(["user", "apiMessage"]),
    headers() {
      let headers = [
        {
          text: "NAME",
          align: "start",
          value: "name",
          sortable: false,
        },
        { text: "CREATE DATE", value: "created_at" },
      ];

      if (this.user.role_id == 1) {
        headers.push({ text: "Suggested Name", value: "suggested_name" });

        headers.push({ text: "Status", value: "status" });
      }
      headers.push({
        text: "",
        value: "actions",
        sortable: false,
        align: "right",
      });

      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add New Company" : "Edit Company";
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formItem.name.$dirty) return errors;
      !this.$v.formItem.name.required &&
        errors.push("Company Name Is Required.");
      return errors;
    },
  },
  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post("https://cpadmin.cat.com.eg/api/company/import", formData, {
          headers,
        })
        .then(() => {
          this.dialogImport = false;
        })
        .catch(() => {});
    },
    editItem(item) {
      this.editedIndex = item.id;
      let editFormItem = {
        name: item.name,
      };
      this.formItem = editFormItem;
      this.dialogProject = true;
    },
    approveItem(item, status) {
      this.editedIndex = item.id;
      let editFormItem = {
        name: item.name,
        id: item.id,
      };
      this.status = status;
      this.formItem = editFormItem;
      this.dialogApprove = true;
    },
    async itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.isLoadingSave = true;

      apiServices
        .update(this.editedIndex, "companies", this.formItem)
        .then((res) => {
          if (res) {
            this.pagination.current_page = 1;
            this.getListData(this.pagination.current_page);
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage)[0];
            console.log("apiMessage", this.apiMessage);
            errors.forEach((err) => {
              this.errorMessage = err;
            });
            this.errorSnackbar = true;
          }
        });
    },
    async itemAddNew() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.isLoadingSave = true;
      await apiServices.post("companies", this.formItem).then((response) => {
        this.isLoadingSave = false;

        if (response) {
          this.pagination.current_page = 1;
          this.getListData(this.pagination.current_page);
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
          let errors = Object.values(this.apiMessage)[0];
          errors.forEach((err) => {
            this.errorMessage = err;
          });
          this.errorSnackbar = true;
        }
      });
    },
    approveSuggestedName(status, company) {
      let editFormItem = {
        company_id: company.toString(),
        status: status,
      };
      this.isLoadingSave = true;
      console.log(editFormItem);
      this.formItem = editFormItem;
      axios.put("company/approve", editFormItem).then((res) => {
        if (res) {
          this.getListData();
          this.isLoadingSave = false;
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    approveNewCompany(status, companyId, companyName) {
      let editFormItem = {
        name: companyName,
        status: status,
      };

      axios.put(`companies/${companyId}`, editFormItem).then(() => {
        this.dialogApprove = false;
        this.getListData(this.pagination.current_page);
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    closeProjectModal() {
      this.editedIndex = -1;
      this.dialogProject = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    closeApproveModal() {
      this.editedIndex = -1;
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    closeImportModal() {
      this.editedIndex = -1;
      this.dialogImport = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      const query = this.searchQuery
        ? `&search=${encodeURIComponent(this.searchQuery)}`
        : "";

      apiServices
        .get(`companies?per_page=7&page=${page}${query}`)
        .then((res) => {
          if (res) {
            this.listData = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },

    searchCampanies() {
      this.pagination.current_page = 1;
      this.getListData(this.pagination.current_page);
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
    "$route.params.query": {
      handler: function () {
        this.getListData(this.pagination.current_page);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_companies.scss";
</style>
